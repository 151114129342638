/* @import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
// Bootstrap layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Bootstrap helpers
@import "bootstrap/scss/helpers";

// Bootstrap utilities
@import "bootstrap/scss/utilities/api"; */

@import './assets/scss/variables';
@import "bootstrap/scss/bootstrap";

//fontawesome
$fa-font-path: "@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

// Bootstrap stylesheets
@import './assets/scss/pages/pricing';

@font-face {
    font-family: 'Staatliches';
    src: url('assets/fonts/Staatliches-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Unna';
    font-weight: normal;
    font-style: normal;
    src: url('assets/fonts/Unna-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Unna';
    font-weight: bold;
    font-style: normal;
    src: url('assets/fonts/Unna-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Unna';
    font-weight: normal;
    font-style: italic;
    src: url('assets/fonts/Unna-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Unna';
    font-weight: bold;
    font-style: italic;
    src: url('assets/fonts/Unna-BoldItalic.ttf') format('truetype');
}

html,
body {
    height: 100%;
    color: #fff;
    background-color: #f4f4f4;
    font-family: 'Unna', serif;
    //background-color: #e6e6e6; //Office color
    //background-color: #0f2027;
    //background-image: linear-gradient(180deg, #000000 -5%, #203a43 50%, #3c6d69 100%);
}

h1,h2,h3,h6 {
    font-family: 'Staatliches', sans-serif;
}
button.btn, a.button {
    font-family: 'Staatliches', sans-serif;
}
input.form-control,
textarea.form-control,
select.form-select {
    font-family: 'Unna', sans-serif;
}
.link {
    cursor: pointer;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    cursor: not-allowed  !important;
    pointer-events: all !important;
    opacity: 0.3 !important;
}
/* html,
body {
    background: #141E30;  
    background: -webkit-linear-gradient(to right, #243B55, #141E30); 
    background: linear-gradient(to right, #243B55, #141E30); 
    
} */

$accordion-bg: #000 !important;

ul[role="listbox"]{
    padding-left: 0px !important;
    margin-bottom: 0px !important;
}
.card-body.padding {
    padding-bottom: 6rem !important;
}